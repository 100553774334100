/**
 * CkInput Component
 *
 * `CkInput` is a custom Input component built upon Ant Design's Input and Form.Item components.
 * This component provides customizable options such as defining the theme, variant, and other properties of the Input and Form Item.
 *
 * @component
 * @example
 * // This is how you can use the CkInput component with light theme and rounded variant
 * <CkInput variant="rounded" />
 *
 * @extends {FormItemProps}
 *
 * @property {"default"|"rounded"} variant - Optional: Specifies the style of the Input component. Possible values are `default` or `rounded`; defaults to `default`
 * @property {IInputProps} inputProps - Optional: Additional properties to pass to the Ant Design Input component
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {boolean} allowClear - Optional: If `true`, an icon will be displayed at the end of the input, which the user can click to clear the input
 * @property {ForwardedRef<any>} ref - Optional: A forwarded ref to be applied to the Form.Item for potential use in parent components
 */
import React, { FC, ForwardedRef } from "react";
import { Input, Form, FormItemProps, InputProps, InputRef } from "antd";
import { CkIcon } from "../../atoms";
import "./styles.css";

interface IInputProps extends InputProps {
  /**
   * Optional: A forwarded ref to be applied to the Input for potential use in parent components
   */
  ref?: ForwardedRef<InputRef>;
}
export interface CkInputProps extends FormItemProps {
  /**
   * Optional: Specifies the style of the Input component. Possible values are `default` or `rounded`; defaults to `default`
   */
  variant?: "default" | "rounded";
  /**
   * Optional: Additional properties to pass to the Ant Design Input component
   */
  inputProps?: IInputProps;
  /**
   * Optional: Additional CSS classes to apply to Form item
   */
  className?: string;
  /**
   * Optional: If `true`, an icon will be displayed at the end of the input, which the user can click to clear the input
   */
  allowClear?: boolean;
  /**
   * Optional: A forwarded ref to be applied to the Form.Item for potential use in parent components
   */
  ref?: ForwardedRef<any>
}

/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * inputProps extends AntD Input, see full docs on https://4x.ant.design/components/input/
 */
const CkInput: FC<CkInputProps> = ({ variant = "default", ref, allowClear, className, inputProps, ...props}) => (
  <Form.Item 
    className={["ck-input", variant, ...(className ? [className] : [])].join(" ")}
    {...(ref ? {ref} : {})}
    {...props}
  >
    <Input 
      {...(allowClear ? { allowClear: {
        clearIcon: <CkIcon name="close" />
      }} : {})}
      {...(inputProps || {})} 
    />
  </Form.Item>
)

export default CkInput;
