/**
 * CkInputCounter Component
 *
 * This is a counter component built upon the AntDesign InputNumber and Form.Item.
 * It includes a decrement button and an increment button and displays the current count.
 * This component allows specifying a function to react to changes in the value.
 * 
 * @component
 * @example
 * // This is how you can use the CkInputCounter with a callback
 * <CkInputCounter value={10} setValue={newValue => console.log(newValue)} />
 *
 * @extends {FormItemProps}
 *
 * @property {number} value - The current count value for the counter
 * @property {Function} setValue - The callback function invoked each time the count is changed
 * @property {IButton} minusButton - Optional: Additional properties for the decrement button
 * @property {IButton} plusButton - Optional: Additional properties for the increment button
 * @property {Function} onInputChange - Optional: Callback function invoked when InputNumber changes
 * @property {IInput} inputProps - Optional: Additional properties for the InputNumber component
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {string} units - Optional: Units of the value to display
 * @property {boolean} noMargin - Optional: If true, removes the margin
 */
import React, { FC } from "react";
import { InputNumber, Form, Button, InputNumberProps, ButtonProps } from "antd";
import { FormItemProps } from "antd/lib/form";
import "./styles.css";

interface IButton extends ButtonProps {}
interface IInput extends InputNumberProps {}
export interface CkInputCounterProps extends FormItemProps {
  /**
   * The current count value for the counter
   */
  value: number;
  /**
   * The callback function invoked each time the count is changed
   */
  setValue: Function;
  /**
   * ptional: Additional properties for the decrement button
   */
  minusButton?: IButton;
  /**
   * Optional: Additional properties for the increment button
   */
  plusButton?: IButton;
  /**
   * Optional: Callback function invoked when InputNumber changes
   */
  onInputChange?: Function;
  /**
   * Optional: Additional properties for the InputNumber component
   */
  inputProps?: IInput;
  /**
   * Optional: Additional CSS classes to apply to Form item
   */
  className?: string;
  /**
   * Optional: Units of the value to display
   */
  units?: string;
  /**
   * Optional: If true, removes the margin of Form.Item
   */
  noMargin?: boolean;
}

const defaultMin = 0;
const defaultMax = 999;

/**
 * Extends AntD Form.Item props
 * See full doc on https://4x.ant.design/components/form/#Form.Item
 * inputProps extends AntD Input, see full docs on https://4x.ant.design/components/input/
 * minusButton and plusButton extends AntD Button, see full docs on https://4x.ant.design/components/button/
 */
const CkInputCounter: FC<CkInputCounterProps> = ({
  minusButton,
  plusButton,
  value,
  setValue,
  onInputChange,
  inputProps,
  className,
  units,
  noMargin,
  ...props
}) => {
  return (
    <Form.Item
      className={[
        "ck-input-counter",
        ...(className ? [className] : []),
        ...(noMargin ? ["no-margin"] : []),
      ].join(" ")}
      {...props}
    >
      <div className="input-container">
        <Button
          onClick={(e) => {
            if (value > (inputProps?.min || defaultMin)) {
              setValue(value - 1);
              minusButton && minusButton?.onClick && minusButton?.onClick(e);
            }
          }}
          {...minusButton}
        >
          -
        </Button>
        <InputNumber
          type="number"
          value={value}
          onChange={(e) => {
            let numb = e;
            if (numb > (inputProps?.max || defaultMin))
              numb = inputProps?.max || defaultMin;
            if (numb < (inputProps?.min || defaultMax))
              numb = inputProps?.min || defaultMax;
            setValue(numb);
            onInputChange && onInputChange(numb);
          }}
          {...(inputProps || {})}
          controls={false}
        />
        <Button
          onClick={(e) => {
            if (value < (inputProps?.max || defaultMax)) {
              setValue(value + 1);
              plusButton && plusButton?.onClick && plusButton?.onClick(e);
            }
          }}
          {...plusButton}
        >
          +
        </Button>
      </div>
      {units && <p className="units">{units}</p>}
    </Form.Item>
  );
};

export default CkInputCounter;
